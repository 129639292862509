
import { defineComponent } from 'vue';
import { defaultCopyrightMarkup } from './settings/options';

export default defineComponent({
  data() {
    return { 
      copyright: defaultCopyrightMarkup
    }
  },
  methods: {
    gotoGithub() {
      window.open("https://github.com/neilg63/radix-app-wasm", "_blank");
    }
  }
})
